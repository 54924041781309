import React, { useState, useEffect } from "react";
import Image from "./image"; 
import { baseURL, baseURLAPI } from "../config"; 


interface Props {
  data: {

    title: string;
    largeImage: string;
    smallImage: string;
  }[]; // Adjust the type of data prop as needed
}

interface GalleryItem {
  id: string;
  title: {
    rendered: string;
  }
  largeImage: string;
  smallImage: string;
  media_details: {
    sizes: {
      medium: {
        source_url: string;
      },
      large: {
        source_url: string;
      },
    };
  };
}


const Gallery: React.FC<Props> = ({ data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [galleryData, setGalleryData] = useState<GalleryItem[]>([]);

  const fetchData = async () => {
    try {
      const parentParam = "12653"; // Set post id
      const pictureCount = '10';
      const response = await fetch(`${baseURLAPI}/media?media_type=image&per_page=${pictureCount}&id=${parentParam}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: GalleryItem[] = await response.json();
  
      console.log('Gallery data:', data);
      if (!Array.isArray(data)) {
        throw new Error("Expected an array in the response data");
      }
      
      // Filter out items with ID less than or equal to 12000
      const filteredData = data.filter(item => {
        const itemId = parseInt(item.id, 10);
        return !isNaN(itemId) && itemId > 12000;
      });
  
      setGalleryData(filteredData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching gallery data:", error);
      setError("Error fetching data. Please try again later.");
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed
            dapibus leo nec.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {error ? (
              <div>{error}</div>
            ) : isLoading ? (
              "Loading..."
            ) : (
              galleryData.map((item, index) => (
                <div key={index} className="col-sm-6 col-md-4 col-lg-4">
                  <Image
                    title={`${item.title.rendered}`}
                    largeImage={`${baseURL}${item.largeImage}`}
                    smallImage={`${baseURL}${item.media_details.sizes.medium.source_url}`}

                  />
                </div>
              ))
            )} 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
