import React, { useState, useEffect } from "react";
import { baseURL, baseURLAPI } from "../config";

interface HeaderProps {
  data: {
    title: string;
    paragraph: string;
  };
}

interface GalleryItem {
  id: string;
  _embedded?: {
    "wp:featuredmedia"?: {
      [index: number]: {
        media_details?: {
          sizes?: {
            large?: {
              source_url?: string;
            };
          };
        };
      };
    };
  };
}

export const Header: React.FC<HeaderProps> = (props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(1); // Start with index 1
  const [galleryData, setGalleryData] = useState<GalleryItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const fetchData = async (parentParams: string[]) => {
    try { 
      const promises = parentParams.map(async (param) => {
        const response = await fetch(`${baseURLAPI}/posts/${param}?_embed&_fields=_embedded`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json() as Promise<GalleryItem>;
      });

      const dataArray = await Promise.all(promises);
      setGalleryData(dataArray);

      const urls = dataArray
        .filter(item => item._embedded && item._embedded["wp:featuredmedia"] && item._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.large?.source_url)
        .map(item => item._embedded!["wp:featuredmedia"]![0].media_details!.sizes!.large!.source_url!);
      setImageUrls(urls);

      setIsLoading(false);
    } catch (error: any) {
      console.error("Error fetching gallery data:", error);
      setError(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const parentParams = ["12653", "12661", "12665", "12667"]; //Post ID Featured image connect
    fetchData(parentParams);
  }, []);

  useEffect(() => {
    if (imageUrls.length > 0) {
      const id = setInterval(cycleBackgroundImages, 3000);
      return () => clearInterval(id);
    }
  }, [imageUrls]);

  const cycleBackgroundImages = () => {
    if (imageUrls.length === 0) {
      console.warn('Image URLs are empty. Skipping cycle.');
      return;
    }

    console.log('Current background URL:', imageUrls[currentImageIndex]);

    setCurrentImageIndex(prevIndex =>
      prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <header id="header">
      <div
        className="intro"
        style={{ backgroundImage: `url(${baseURL}${imageUrls[currentImageIndex]})` }}
      >
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
