import React, { useState, useEffect } from "react";
import Navigation from "./components/navigation";
import Header from "./components/header";
import Features from "./components/features";
import About from "./components/about";
import Services from "./components/services";
import Gallery from "./components/gallery";
import Testimonials from "./components/testimonials";
import Team from "./components/Team";
import Contact from "./components/contact";
import JsonData from "./data/data";
import SmoothScroll from "smooth-scroll";
import "./App.css";

const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

interface LandingPageData {
  Contact: { address: string; phone: string; email: string; facebook: string; twitter: string; youtube: string; };
  Team: { img: string; name: string; job: string }[] | null;
  Testimonials: { img: string; text: string; name: string }[] | null;
  Gallery: { title: string; largeImage: string; smallImage: string }[];
  Services: { icon: string; name: string; text: string }[] | null;
  About: { paragraph: string; Why: string[]; Why2: string[]; };
  Header: { title: string; paragraph: string };
  Features: { icon: string; title: string; text: string }[];
}

const App: React.FC = () => {
  const [landingPageData, setLandingPageData] = useState<LandingPageData | null>(null); // Set initial state as null

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  // Render conditionally based on landingPageData
  if (!landingPageData) {
    return <div>Loading...</div>; // Placeholder for loading state
  }

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;


